import { Role } from "./Role";
import { Tenant } from "./Tenant";

export interface User {
  Env: string;
  Mode: string;
  ID: number;
  Email: string;
  FirstName: string;
  LastName: string;
  Status: UserStatus;
  LastLoginTimestamp: number;
  RegistrationTimestamp: number;
  Roles: Role[];
  Tenant: Tenant;
}

export enum UserStatus {
  PENDING = 1,
  ACTIVE = 2,
  DEACTIVATED = 3,
  INACTIVE = 4
}

export const USER_ACTION_TYPE = {
  OPEN_NEW_USER_MODAL: "OPEN_NEW_USER_MODAL",
  CLOSE_NEW_USER_MODAL: "CLOSE_NEW_USER_MODAL",
  OPEN_USER_MODAL: "OPEN_USER_MODAL",
  CLOSE_USER_MODAL: "CLOSE_USER_MODAL",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  DISABLE: "DISABLE",
  FILTER_USERS: "FILTER_USERS"
};
