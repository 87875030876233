
















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { User, UserStatus } from "../../../../datatypes/User";
import Emitter from "../../../../services/events/event-bus";

enum Tab {
  PENDING = 1,
  APPROVED = 2,
  DEACTIVATED = 3
}

@Component
export default class UsersToolbar extends Vue {
  @Prop(Array) users!: User[];
  tab: Tab = Tab.APPROVED;
  searchtext: string = "";
  emitter: Vue = Emitter;

  mounted() {
    this.subscriptions();
  }

  subscriptions() {
    this.emitter.$on("clear-user-search-text", () => {
      this.searchtext = "";
    });

    this.emitter.$on("admin-console-tab-change", (index: number) => {
      if (index === 0) {
        this.emitter.$emit("set-active-tab", this.tab);
      }
    });
  }

  @Watch("tab", { immediate: true })
  ontabswitch() {
    this.emitter.$emit("set-active-tab", this.tab);
  }

  get pendingusers() {
    if (!Array.isArray(this.users)) {
      return [];
    }
    return this.users.filter(user => user.Status === UserStatus.PENDING);
  }

  get approvedusers() {
    if (!Array.isArray(this.users)) {
      return [];
    }
    return this.users.filter(user => user.Status === UserStatus.ACTIVE);
  }

  get deactivatedusers() {
    if (!Array.isArray(this.users)) {
      return [];
    }
    return this.users.filter(user => user.Status === UserStatus.DEACTIVATED);
  }

  get activetabstyle() {
    return `
    text-decoration: underline; 
    text-decoration-color: rgb(248, 222, 42); 
    text-decoration-style: inherit;
    `;
  }
}
