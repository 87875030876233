




































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Claim, ClaimStatus } from "../../../../datatypes/Claim";
import Emitter from "../../../../services/events/event-bus";
import { Role } from "../../../../datatypes/Role";

enum Tab {
  CREATED = 1,
  SUBMITTED = 2,
  ACKNOWLEDGED = 3
}

@Component
export default class ClaimsToolbar extends Vue {
  @Prop(Array) claims!: Claim[];
  tab: Tab = Tab.CREATED;
  searchtext: string = "";
  emitter: Vue = Emitter;

  mounted() {
    this.subscriptions();
  }

  subscriptions() {
    this.emitter.$on("clear-claim-search-text", () => {
      this.searchtext = "";
    });

    this.emitter.$on("admin-console-tab-change", (index: number) => {
      if (index === 1) {
        this.tab = Tab.CREATED;
      }
    });
  }

  @Watch("tab", { immediate: true })
  ontabswitch() {
    this.emitter.$emit("set-active-tab", this.tab);
  }

  get role(): Role | null {
    return this.$store.getters["session/role"];
  }

  get isUberAdmin() {
    // UBER ADMIN ROLE ID === 3
    return this.role ? this.role.ID === 3 : false;
  }

  get isRainnAdmin() {
    // RAINN ADMIN ROLE ID === 1
    return this.role ? this.role.ID === 1 : false;
  }

  get tabDisplayFlags() {
    return { created: true, submitted: this.isRainnAdmin, acknowledged: false };
  }

  get createdclaims() {
    if (!Array.isArray(this.claims)) {
      return [];
    }
    return this.claims.filter(claim => claim.Status === ClaimStatus.CREATED);
  }

  get submittedclaims() {
    if (!Array.isArray(this.claims)) {
      return [];
    }
    return this.claims.filter(claim => claim.Status === ClaimStatus.SUBMITTED);
  }

  get acknowledgedclaims() {
    if (!Array.isArray(this.claims)) {
      return [];
    }
    return this.claims.filter(
      claim => claim.Status === ClaimStatus.ACKNOWLEDGED
    );
  }

  get activetabstyle() {
    return `
    text-decoration: underline; 
    text-decoration-color: rgb(248, 222, 42); 
    text-decoration-style: inherit;
    `;
  }

  create() {
    window.location.replace("/create-referral");
  }

  download(version: string) {
    const param = ["uber:default", "rainn:default"].includes(version.trim())
      ? version.trim()
      : "uber:default";
    const link = `${window.location.origin}/api/v1/claims?download=true&download_version=${param}`;

    window.open(link, "_blank");
  }
}
