


























































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Role, RoleType } from "../../datatypes/Role";
import { User, UserStatus } from "../../datatypes/User";
import dateformat from "../../util/date-format";
import capitalize from "lodash/capitalize";
import axios from "axios";

import get from "lodash/get";
import flatten from "lodash/flatten";
import { scrollBehavior } from "../../util/dom";
import has from "lodash/has";
import { rules } from "../../util/validation";

import { getCSRFToken, extractErrorMessage } from "../../services/actions";

import {
  MIN_PERSON_NAME_LENGTH,
  MAX_PERSON_NAME_LENGTH,
  MIN_EMAIL_ADDRESS_LENGTH,
  MAX_EMAIL_ADDRESS_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  PERSON_NAME_REGEX,
  USER_ROLE_ID
} from "../../services/config/constants";
import { removeDiacritics } from "../../util/string";

@Component
export default class UserUpdateForm extends Vue {
  csrfToken: string = "";
  firstname: string = "";
  lastname: string = "";
  password: string = "";
  email: string = "";
  role: number = Number.NaN;
  grants: number[] = [];
  status: number = Number.NaN;
  loading: boolean = false;
  passwordFieldToggle: boolean = false;
  formerrors: Record<string, string[]> = {};
  specialcharacters: string = "(! @ # $ % ^ & * ( ) _ + = - ~ . / ? > < , .)";

  @Prop() user!: User;
  @Prop(Array) roles!: Role[];

  submitting: boolean = false;
  errors: string[] = [];

  @Watch("passwordFieldToggle", { immediate: true })
  cancelPasswordReset(state: boolean) {
    if (typeof state === "boolean" && state === false) {
      this.password = "";
    }
  }

  mounted() {
    this.getCSRFToken();
    this.initializeform();
    if (this.isDeactivatedUser) {
      return (this.passwordFieldToggle = true);
    }
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  get isDeactivatedUser() {
    return [UserStatus.DEACTIVATED].includes(this.user.Status);
  }
  get isSelf() {
    return this.me && this.user && Number(this.me.ID) === Number(this.user.ID);
  }

  get me(): User | null {
    return this.$store.getters["session/user"];
  }

  get primitiveRoles(): Role[] {
    return Array.isArray(this.roles)
      ? this.roles.filter((r: Role) => r.Type === RoleType.PRIMITIVE)
      : [];
  }

  get grantRoles(): Role[] {
    return Array.isArray(this.roles)
      ? this.roles.filter((r: Role) => r.Type === RoleType.GRANT)
      : [];
  }

  get constants() {
    return {
      MIN_PERSON_NAME_LENGTH,
      MAX_PERSON_NAME_LENGTH,
      MIN_EMAIL_ADDRESS_LENGTH,
      MAX_EMAIL_ADDRESS_LENGTH,
      MIN_PASSWORD_LENGTH,
      MAX_PASSWORD_LENGTH,
      PERSON_NAME_REGEX
    };
  }

  validation() {
    this.formerrors = [
      "firstname",
      "lastname",
      "email",
      "password",
      "role",
      "grants"
    ].reduce((errorbag: Record<string, string[]>, field: string) => {
      switch (field) {
        case "firstname": {
          const isvalid = rules.string.name(get(this.$data, field));
          return isvalid
            ? errorbag
            : {
                ...errorbag,
                firstname: ["Valid first name is required. Minimum length 2"]
              };
        }

        case "lastname": {
          const isvalid = rules.string.name(get(this.$data, field));
          return isvalid
            ? errorbag
            : {
                ...errorbag,
                lastname: ["Valid last name is required. Minimum length 2"]
              };
        }

        case "email": {
          const isvalid = rules.string.email(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, email: ["Valid email address is required"] };
        }

        case "password": {
          const isvalid = rules.string.password(get(this.$data, field));
          return isvalid
            ? errorbag
            : this.passwordFieldToggle
            ? { ...errorbag, password: ["Valid password is required"] }
            : errorbag;
        }

        case "role": {
          const role = get(this.$data, field);
          const isvalid = role && !isNaN(Number(role));
          return isvalid
            ? errorbag
            : { ...errorbag, role: ["Valid role is required"] };
        }

        case "grants": {
          const grants = Array.isArray(get(this.$data, field))
            ? get(this.$data, field)
            : [];

          const grantRoleIds = this.grantRoles.map((r: Role) => r.ID);

          const isvalid =
            !grants.length ||
            grants.every((g: number) => grantRoleIds.includes(g));

          return isvalid
            ? errorbag
            : { ...errorbag, grants: ["Valid grants required"] };
        }

        default: {
          return errorbag;
        }
      }
    }, {} as Record<string, string[]>);
  }

  private formattimestamp(timestamp: number) {
    if (timestamp === -1) {
      return "Never Logged In";
    }
    // return dateformat("MM/dd/yyyy pp", timestamp);
    return dateformat("Pp", timestamp);
    // return dateformat("Pp", timestamp);;
  }

  formatrole(role: Role) {
    switch (role.Type) {
      case RoleType.PRIMITIVE: {
        return capitalize(
          role.Name.toLowerCase()
            .replace("claim", "referral")
            .split("_")[1]
        );
      }

      case RoleType.GRANT: {
        switch (role.ID) {
          case USER_ROLE_ID.CLAIM_REQUEST_REVIEWER: {
            return "Escalation Request Reviewer";
          }

          default: {
            return role.Name.toLowerCase()
              .replace("claim", "referral")
              .split("_")
              .map(capitalize)
              .join(" ");
          }
        }
      }
    }
  }

  private initializeform() {
    this.firstname = this.user.FirstName;
    this.lastname = this.user.LastName;
    this.email = this.user.Email;
    const primitiveRoleIds = this.primitiveRoles.map((r: Role) => r.ID);
    const assignedPrimitiveRole = this.user.Roles.find(r =>
      primitiveRoleIds.includes(r.ID)
    );
    this.role = assignedPrimitiveRole
      ? Number(assignedPrimitiveRole.ID)
      : Number.NaN;
    this.grants = this.user.Roles.slice(1).map(r => r.ID);
    this.status = this.user.Status;
  }

  private resetpassword() {
    this.passwordFieldToggle = confirm("Please confirm password reset.");
  }

  private reactivate() {
    this.submitting = true;
    this.errors = [];
    const { role, status, password, csrfToken, grants } = this.$data;
    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.submitting = false;
      this.errors = flatten(Object.values(this.formerrors));
      scrollBehavior("#user-update-errors");
      return;
    }

    if (this.passwordFieldToggle && !rules.string.password(password)) {
      this.submitting = false;
      this.errors = ["Valid password is required"];
      scrollBehavior("#user-update-errors");
      return;
    }

    const confirmation = confirm("Please confirm user reactivation.");
    if (confirmation) {
      axios({
        url: `/api/v1/users/${this.user.ID}`,
        method: "PATCH",
        data: {
          role,
          grants: [...new Set<number>(grants).keys()],
          status: 1,
          password,
          _csrf: csrfToken
        }
      })
        .then(() => {
          // this.submitting = false;
          window.location.reload();
        })
        .catch(err => {
          this.getCSRFToken();
          this.submitting = false;
          console.error("user reactivation error", err);
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#user-update-errors");
        });
    }
  }

  private deactivate() {
    const confirmation = confirm("Please confirm user deactivation.");
    if (confirmation) {
      this.submitting = true;
      this.errors = [];
      const { role, grants, status, csrfToken } = this.$data;

      axios({
        url: `/api/v1/users/${this.user.ID}`,
        method: "PATCH",
        data: {
          role,
          grants: [...new Set<number>(grants).keys()],
          status: 3,
          _csrf: csrfToken
        }
      })
        .then(() => {
          this.submitting = false;
          window.location.replace(window.location.href);
        })
        .catch(err => {
          this.getCSRFToken();
          this.submitting = false;
          console.error("user deactivation error", err);
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#user-update-errors");
        });
    }
  }

  private submit() {
    const {
      firstname,
      lastname,
      email,
      role,
      grants,
      password,
      status,
      csrfToken
    } = this.$data;

    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.errors = flatten(Object.values(this.formerrors));
      scrollBehavior("#user-update-errors");
      return;
    }

    if (this.passwordFieldToggle && !rules.string.password(password)) {
      this.errors = ["Valid password is required"];
      scrollBehavior("#user-update-errors");
      return;
    }

    const confirmation = confirm("Please confirm user update.");
    if (confirmation) {
      this.errors = [];
      this.submitting = true;
      axios({
        url: `/api/v1/users/${this.user.ID}`,
        method: "PATCH",
        data: Object.assign(
          {
            firstname: removeDiacritics(firstname),
            lastname: removeDiacritics(lastname),
            role,
            grants: [...new Set<number>(grants).keys()],
            status,
            _csrf: csrfToken
          },
          this.passwordFieldToggle ? { password: this.password } : {}
        )
      })
        .then(() => {
          this.submitting = false;
          window.location.replace(window.location.href);
        })
        .catch(err => {
          this.getCSRFToken();
          this.submitting = false;
          console.error("user update error", err);
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#user-update-errors");
        });
    }
  }

  private cleanup() {
    this.initializeform();
    this.errors = [];
    this.formerrors = {};
    this.passwordFieldToggle = false;
    this.csrfToken = "";
    this.submitting = false;
    this.getCSRFToken();
  }

  private clear() {
    this.cleanup();
    //@ts-ignore
    this.$parent.close();
  }
}
