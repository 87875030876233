







































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Role, RoleType } from "../../datatypes/Role";
import {
  MIN_PERSON_NAME_LENGTH,
  MAX_PERSON_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_EMAIL_ADDRESS_LENGTH,
  MAX_EMAIL_ADDRESS_LENGTH,
  PERSON_NAME_REGEX,
  USER_ROLE_ID
} from "../../services/config/constants";

import { removeDiacritics } from "../../util/string";

import { createuser, getCSRFToken } from "../../services/actions";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import flatten from "lodash/flatten";
import { scrollBehavior } from "../../util/dom";
import has from "lodash/has";
import { rules } from "../../util/validation";

enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

@Component
export default class UserCreationForm extends Vue {
  firstname: string = "";
  lastname: string = "";
  email: string = "";
  password: string = "";
  csrfToken: string = "";
  role: number = Number.NaN;
  grants: number[] = [];
  specialcharacters: string = "(! @ # $ % ^ & * ( ) _ + = - ~ . / ? > < , .)";
  @Prop(Array) roles!: Role[];

  status: Status = Status.IDLE;
  loading: boolean = false;
  errors: string[] = [];
  formerrors: Record<string, string[]> = {};

  get constants() {
    return {
      MIN_PERSON_NAME_LENGTH,
      MAX_PERSON_NAME_LENGTH,
      MIN_EMAIL_ADDRESS_LENGTH,
      MAX_EMAIL_ADDRESS_LENGTH,
      MIN_PASSWORD_LENGTH,
      MAX_PASSWORD_LENGTH,
      PERSON_NAME_REGEX
    };
  }

  mounted() {
    this.getCSRFToken();
    this.role = this.primitiveRoles.length
      ? this.primitiveRoles[0].ID
      : Number.NaN;
  }

  get primitiveRoles(): Role[] {
    return Array.isArray(this.roles)
      ? this.roles.filter((r: Role) => r.Type === RoleType.PRIMITIVE)
      : [];
  }

  get grantRoles(): Role[] {
    return Array.isArray(this.roles)
      ? this.roles.filter((r: Role) => r.Type === RoleType.GRANT)
      : [];
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  validation() {
    this.formerrors = [
      "firstname",
      "lastname",
      "email",
      "password",
      "role",
      "grants"
    ].reduce((errorbag: Record<string, string[]>, field: string) => {
      switch (field) {
        case "firstname": {
          const isvalid = rules.string.name(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, firstname: ["Valid first name is required"] };
        }

        case "lastname": {
          const isvalid = rules.string.name(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, lastname: ["Valid last name is required"] };
        }

        case "email": {
          const isvalid = rules.string.email(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, email: ["Valid email address is required"] };
        }

        case "password": {
          const isvalid = rules.string.password(get(this.$data, field));
          return isvalid
            ? errorbag
            : { ...errorbag, password: ["Valid password is required"] };
        }

        case "role": {
          const role = get(this.$data, field);
          const isvalid = role && !isNaN(Number(role));
          return isvalid
            ? errorbag
            : { ...errorbag, role: ["Valid role is required"] };
        }

        case "grants": {
          const grants = Array.isArray(get(this.$data, field))
            ? get(this.$data, field)
            : [];

          const grantRoleIds = this.grantRoles.map((r: Role) => r.ID);

          const isvalid =
            !grants.length ||
            grants.every((g: number) => grantRoleIds.includes(g));

          return isvalid
            ? errorbag
            : { ...errorbag, grants: ["Valid grants required"] };
        }

        default: {
          return errorbag;
        }
      }
    }, {} as Record<string, string[]>);
  }

  submit() {
    this.loading = true;
    this.errors = [];
    this.status = Status.SUBMITTING;

    const {
      firstname,
      lastname,
      email,
      password,
      role,
      grants,
      csrfToken
    } = this.$data;

    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.errors = flatten(Object.values(this.formerrors));
      this.status = Status.IDLE;
      this.loading = false;
      scrollBehavior("#user-creation-errors");
      return;
    }

    createuser(
      {
        firstname: removeDiacritics(firstname),
        lastname: removeDiacritics(lastname),
        email,
        password,
        role,
        grants: [...new Set<number>(grants).keys()],
        _csrf: csrfToken
      },
      () => {
        // this.status = Status.IDLE;
        // this.loading = false;
        window.location.reload();
      },
      (errors: Error | Error[]) => {
        this.getCSRFToken();
        this.status = Status.IDLE;
        this.loading = false;
        this.errors = Array.isArray(errors)
          ? errors.map(e => e.message)
          : [errors.message];
        console.error("user creation failed", this.errors);
        scrollBehavior("#user-creation-errors");
      }
    );
  }

  formatrole(role: Role) {
    switch (role.Type) {
      case RoleType.PRIMITIVE: {
        return capitalize(
          role.Name.toLowerCase()
            .replace("claim", "referral")
            .split("_")[1]
        );
      }

      case RoleType.GRANT: {
        switch (role.ID) {
          case USER_ROLE_ID.CLAIM_REQUEST_REVIEWER: {
            return "Escalation Request Reviewer";
          }

          default: {
            return role.Name.toLowerCase()
              .replace("claim", "referral")
              .split("_")
              .map(capitalize)
              .join(" ");
          }
        }
      }
    }
  }

  cleanup() {
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.password = "";
    this.formerrors = {};
    this.role = Number.NaN;
    this.errors = [];
    this.csrfToken = "";
    this.loading = false;
    this.status = Status.IDLE;
    const $form = document.getElementById("user-creation") as HTMLFormElement;
    if ($form) {
      $form.reset();
    }
  }

  clear() {
    this.cleanup();
    //@ts-ignore
    this.$parent.close();
  }

  beforeDestroy() {
    this.cleanup();
  }
}
