























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { extractErrorMessage, getCSRFToken } from "../../services/actions";
import { nonEmptyString } from "../../util/string";
import { scrollBehavior } from "../../util/dom";
import axios from "axios";

import {
  MIN_CLAIM_SID_LENGTH,
  MAX_CLAIM_SID_LENGTH,
  MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH,
  MAX_CLAIM_REQUEST_DESCRIPTION_LENGTH
} from "../../services/config/constants";
import { Role } from "../../datatypes/Role";
import { User } from "../../datatypes/User";

@Component
export default class ClaimRequestCreationForm extends Vue {
  pin: string = "";
  description: string = "";
  csrfToken: string = "";

  loading: boolean = false;
  errors: string[] = [];

  mounted() {
    this.getCSRFToken();
  }

  get constants() {
    return {
      MIN_CLAIM_SID_LENGTH,
      MAX_CLAIM_SID_LENGTH,
      MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH,
      MAX_CLAIM_REQUEST_DESCRIPTION_LENGTH
    };
  }

  submit() {
    this.loading = true;
    this.errors = [];

    const validPin =
      nonEmptyString(this.pin) &&
      this.pin.trim().length >= this.constants.MIN_CLAIM_SID_LENGTH &&
      this.pin.trim().length <= this.constants.MAX_CLAIM_SID_LENGTH;

    const validDescription =
      nonEmptyString(this.description) &&
      this.description.trim().length >=
        this.constants.MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH &&
      this.description.trim().length <=
        this.constants.MAX_CLAIM_REQUEST_DESCRIPTION_LENGTH;

    this.errors = ([] as string[])
      .concat(validPin ? [] : ["Valid PIN is required. Minimum length 7"])
      .concat(
        validDescription ? [] : ["Valid escalation description is required"]
      );

    if (this.errors.length) {
      this.loading = false;
      console.debug("errors for claim request form", this.errors);
      scrollBehavior("#claim-request-creation-errors");
      return;
    }

    if (confirm("Please confirm escalation request")) {
      axios({
        url: `/api/v1/claim-requests`,
        method: "POST",
        data: {
          pin: this.pin.trim(),
          description: this.description.trim(),
          _csrf: this.csrfToken
        }
      })
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          this.getCSRFToken();
          this.loading = false;
          console.error("claim request creation error", err);
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#claim-request-creation-errors");
        });
    }
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  cleanup() {
    this.errors = [];
    this.loading = false;
    const $form = document.getElementById(
      `claim-request-creation`
    ) as HTMLFormElement;
    if ($form) {
      $form.reset();
    }
  }

  clear() {
    this.cleanup();
    //@ts-ignore
    this.$parent.close();
  }

  beforeDestroy() {
    this.cleanup();
  }
}
