















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import random from "crypto-random-string";
import {
  ClaimRequest,
  ClaimRequestStatus
} from "../../../../../datatypes/Claim";
import Emitter from "../../../../../services/events/event-bus";
import dateformat from "../../../../../util/date-format";
import { Role } from "../../../../../datatypes/Role";
import { USER_ROLE_ID } from "../../../../../services/config/constants";

interface Column {
  id: string;
  key: string;
  field: string;
  label: string;
  centered: boolean;
  sortable: boolean;
  numeric: boolean;
  visible: boolean;
}

@Component
export default class ReviewedClaimRequestsTable extends Vue {
  emitter: Vue = Emitter;
  @Prop(Array) claimrequests!: ClaimRequest[];
  @Prop(Boolean) loading!: boolean;
  perpage: number = 5;

  get columns(): Column[] {
    return [
      {
        id: this.randomid(),
        key: "sid",
        field: "SID",
        label: "Request ID",
        centered: false,
        visible: true,
        sortable: false,
        numeric: false
      }
    ]
      .concat(
        this.isRainnAdmin
          ? [
              {
                id: this.randomid(),
                key: "claimpin",
                field: "Claim.SID",
                label: "Referral",
                visible: true,
                centered: false,
                sortable: false,
                numeric: false
              }
            ]
          : []
      )
      .concat(
        this.isRainnAdmin
          ? [
              {
                id: this.randomid(),
                key: "user",
                field: "ClaimRequestReviewUser.Email",
                label: "User",
                visible: true,
                centered: false,
                sortable: true,
                numeric: false
              }
            ]
          : []
      )
      .concat([
        {
          id: this.randomid(),
          key: "reviewtimestamp",
          field: "ReviewTimestamp",
          label: "Adjudicated",
          centered: false,
          visible: true,
          sortable: true,
          numeric: true
        },
        {
          id: this.randomid(),
          key: "action",
          field: "Description",
          label: "",
          centered: false,
          visible: true,
          sortable: false,
          numeric: false
        }
      ]);
  }

  get role(): Role | null {
    return this.$store.getters["session/role"];
  }

  get isRainnAdmin() {
    return this.role ? this.role.ID === USER_ROLE_ID.RAINN_ADMIN : false;
  }

  private columnvalue(
    columnkey: string,
    columnfield: keyof ClaimRequest,
    claimrequest: ClaimRequest
  ) {
    switch (columnkey) {
      case "sid": {
        return claimrequest.SID;
      }

      case "claimpin": {
        return claimrequest.Claim.SID;
      }

      case "status": {
        switch (claimrequest.Status) {
          case ClaimRequestStatus.PENDING: {
            return "Review";
          }
          case ClaimRequestStatus.APPROVED: {
            return "Approved";
          }
          case ClaimRequestStatus.REJECTED: {
            return "Rejected";
          }
          case ClaimRequestStatus.COMPLETED: {
            return "Completed";
          }

          default: {
            return "";
          }
        }
      }
      case "user": {
        return claimrequest.ClaimRequestReviewUser.Email;
      }
      default: {
        return this.formattimestamp(Number(claimrequest[columnfield]));
      }
    }
  }

  private formattimestamp(timestamp: number) {
    return timestamp === -1 ? "" : dateformat("Pp", timestamp);
  }

  private randomid() {
    return random({ length: 10, type: "base64" });
  }
}
