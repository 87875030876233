






















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ClaimRequest, ClaimRequestStatus } from "../../datatypes/Claim";
import dateformat from "../../util/date-format";
import { extractErrorMessage, getCSRFToken } from "../../services/actions";
import { nonEmptyString } from "../../util/string";
import isPlainObject from "lodash/isPlainObject";
import { zonedTimeToUtc } from "date-fns-tz";
import { scrollBehavior } from "../../util/dom";
import axios from "axios";

import { USER_ROLE_ID } from "../../services/config/constants";
import { Role } from "../../datatypes/Role";
import { User } from "../../datatypes/User";

@Component
export default class ClaimRequestProcessingForm extends Vue {
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  today: Date = new Date();
  description: string = "";
  @Prop() claimrequest!: ClaimRequest;
  @Prop(Array) statuses!: ClaimRequestStatus[];
  reviewuser: string = "";
  creationuser: string = "";
  completionuser: string = "";
  creationtimestamp: string = "";
  reviewtimestamp: string = "";
  completiontimestamp: string = "";
  status: ClaimRequestStatus = ClaimRequestStatus.PENDING;
  csrfToken: string = "";

  loading: boolean = false;
  errors: string[] = [];

  mounted() {
    this.getCSRFToken();
    this.initializeform();
  }

  get isCompleted() {
    return (
      this.claimrequest &&
      isPlainObject(this.claimrequest) &&
      this.claimrequest.Status === ClaimRequestStatus.COMPLETED
    );
  }

  get isPending() {
    return (
      this.claimrequest &&
      isPlainObject(this.claimrequest) &&
      this.claimrequest.Status === ClaimRequestStatus.PENDING
    );
  }

  get isReviewed() {
    return (
      this.claimrequest &&
      isPlainObject(this.claimrequest) &&
      [ClaimRequestStatus.APPROVED, ClaimRequestStatus.REJECTED].includes(
        this.claimrequest.Status
      )
    );
  }

  get role(): Role | null {
    return this.$store.getters["session/role"];
  }

  get isUberAdmin() {
    return this.role ? this.role.ID === USER_ROLE_ID.UBER_ADMIN : false;
  }

  get isRainnAdmin() {
    return this.role ? this.role.ID === USER_ROLE_ID.RAINN_ADMIN : false;
  }

  get filteredstatuses() {
    if (this.isCompleted) {
      return [ClaimRequestStatus.COMPLETED];
    }

    if (this.isPending) {
      return this.isUberAdmin
        ? [
            ClaimRequestStatus.PENDING,
            ClaimRequestStatus.APPROVED,
            ClaimRequestStatus.REJECTED
          ]
        : [ClaimRequestStatus.PENDING];
    }

    if (this.isReviewed) {
      return this.isUberAdmin
        ? [ClaimRequestStatus.APPROVED, ClaimRequestStatus.REJECTED]
        : [this.claimrequest.Status, ClaimRequestStatus.COMPLETED];
    }

    return [this.claimrequest.Status];
  }

  initializeform() {
    this.creationuser = this.formatuser(
      this.claimrequest.ClaimRequestCreationUser
    );
    this.reviewuser = this.formatuser(this.claimrequest.ClaimRequestReviewUser);
    this.completionuser = this.formatuser(
      this.claimrequest.ClaimRequestCompletionUser
    );
    this.description = nonEmptyString(this.claimrequest.Description)
      ? this.claimrequest.Description
      : "";
    this.creationtimestamp = this.formattimestamp(
      this.claimrequest.CreationTimestamp
    );
    this.reviewtimestamp = this.formattimestamp(
      this.claimrequest.ReviewTimestamp
    );
    this.completiontimestamp = this.formattimestamp(
      this.claimrequest.CompletionTimestamp
    );
    this.status = this.claimrequest.Status;
  }

  formattimestamp(timestamp: string | number | null) {
    const val = Number(timestamp);
    return isNaN(val) || val === -1 ? "" : dateformat("yyyy-MM-dd", val);
  }

  formatstatus(status: number) {
    switch (status) {
      case ClaimRequestStatus.PENDING: {
        return "Pending";
      }
      case ClaimRequestStatus.APPROVED: {
        return "Approved";
      }
      case ClaimRequestStatus.REJECTED: {
        return "Rejected";
      }
      case ClaimRequestStatus.COMPLETED: {
        return "Completed";
      }

      default: {
        return "";
      }
    }
  }

  formatuser(user: User | null) {
    if (!user) {
      return "";
    }

    return `${user.FirstName} ${user.LastName} ( ${user.Email} )`;
  }

  submit() {
    this.loading = true;
    const { csrfToken, status } = this.$data;
    if (status === this.claimrequest.Status) {
      this.clear();
      return;
    }

    if (confirm("Please confirm escalation request status update")) {
      axios({
        url: `/api/v1/claim-requests/status/${this.claimrequest.ID}`,
        method: "POST",
        data: {
          status,
          description: nonEmptyString(this.description.trim())
            ? this.description.trim()
            : " ",
          _csrf: csrfToken
        }
      })
        .then(() => {
          window.location.reload();
        })
        .catch(err => {
          this.getCSRFToken();
          this.loading = false;
          console.error("claim request update error", err);
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#claim-request-info-errors");
        });
    }
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  cleanup() {
    this.errors = [];
    this.loading = false;
    const $form = document.getElementById(
      `claim-request-${this.claimrequest.ID}-info`
    ) as HTMLFormElement;
    if ($form) {
      $form.reset();
    }

    this.initializeform();
  }

  clear() {
    this.cleanup();
    //@ts-ignore
    this.$parent.close();
  }

  beforeDestroy() {
    this.cleanup();
  }
}
