









































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { User } from "../../../../../datatypes/User";
import Emitter from "../../../../../services/events/event-bus";
import dateformat from "../../../../../util/date-format";
import capitalize from "lodash/capitalize";

@Component
export default class PendingUsersTable extends Vue {
  @Prop(Array) users!: User[];
  @Prop(Boolean) loading!: boolean;
  perpage: number = 5;
  emitter: Vue = Emitter;

  CONFIG: Record<string, unknown> = {
    narrowed: true,
    hoverable: true,
    mobilecards: true,
    checkable: false,
    sortdirection: "desc",
    paginated: true,
    sorticonsize: "is-small",
    columns: {
      firstname: {
        field: "FirstName",
        label: "First Name",
        centered: false,
        // searchable: true,
        sortable: true
      },
      lastname: {
        field: "LastName",
        label: "Last Name",
        centered: false,
        // searchable: true,
        sortable: true
      },
      email: {
        field: "Email",
        label: "Email",
        centered: false,
        // searchable: true,
        sortable: true
      },
      role: {
        field: "Roles[0].Name",
        label: "Role",
        centered: false,
        // searchable: true,
        sortable: true
      },
      timestamp: {
        field: "LastLoginTimestamp",
        label: "Last Signed In",
        centered: false,
        sortable: true,
        numeric: true
      },
      action: {
        field: "ID",
        label: "       ",
        centered: false,
        sortable: false
      }
    }
  };

  private formattimestamp(timestamp: number) {
    return timestamp === -1 ? "Never Logged In" : dateformat("Pp", timestamp);
  }

  private formatrole(name: string) {
    return capitalize(name.trim().split("_")[1]);
  }
}
