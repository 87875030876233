






















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import random from "crypto-random-string";
import { Claim } from "../../../../../datatypes/Claim";
import dateformat from "../../../../../util/date-format";
import { LINE_OF_BUSINESS_FILER } from "../../../../../services/config/constants";

interface Column {
  id: string;
  key: string;
  field: string;
  label: string;
  centered: boolean;
  sortable: boolean;
  numeric: boolean;
  visible: boolean;
}

@Component
export default class AcknowledgedClaimsTable extends Vue {
  @Prop(Array) claims!: Claim[];
  @Prop(Boolean) loading!: boolean;
  perpage: number = 5;
  columns: Column[] = [
    {
      id: this.randomid(),
      key: "sid",
      field: "SID",
      label: "Unique PIN",
      centered: false,
      visible: true,
      sortable: false,
      numeric: false
    },
    {
      id: this.randomid(),
      key: "program",
      field: "LineOfBusiness.Program",
      label: "Program",
      visible: true,
      centered: false,
      sortable: false,
      numeric: false
    },
    {
      id: this.randomid(),
      key: "category",
      field: "Category.Name",
      label: "Category",
      visible: true,
      centered: false,
      sortable: true,
      numeric: false
    },
    {
      id: this.randomid(),
      key: "lob",
      field: "LineOfBusiness.Name",
      label: "LOB",
      visible: true,
      centered: false,
      sortable: false,
      numeric: false
    },
    {
      id: this.randomid(),
      key: "user",
      field: "CreationUser.Email",
      label: "User",
      visible: true,
      centered: false,
      sortable: true,
      numeric: false
    },
    {
      id: this.randomid(),
      key: "acknowledgementtimestamp",
      field: "AcknowledgementTimestamp",
      label: "Acknowledged",
      centered: false,
      visible: false,
      sortable: true,
      numeric: true
    },
    {
      id: this.randomid(),
      key: "fundstarttimestamp",
      field: "CreationTimestamp",
      label: "Fund Start",
      centered: false,
      visible: true,
      sortable: true,
      numeric: true
    },
    {
      id: this.randomid(),
      key: "fundendtimestamp",
      field: "ExpirationTimestamp",
      label: "Fund End",
      centered: false,
      visible: true,
      sortable: true,
      numeric: true
    }
  ];

  private columnvalue(
    columnkey: string,
    columnfield: keyof Claim,
    claim: Claim
  ) {
    switch (columnkey) {
      case "sid": {
        return claim.SID;
      }
      case "program": {
        return claim.LineOfBusiness.Program;
      }
      case "category": {
        return claim.Category.Name;
      }
      case "lob": {
        if (LINE_OF_BUSINESS_FILER.has(claim.LineOfBusiness.Name)) {
          return "n/a";
        }
        return claim.LineOfBusiness.Name;
      }
      case "user": {
        return claim.CreationUser.Email;
      }
      default: {
        return this.formattimestamp(Number(claim[columnfield]));
      }
    }
  }

  private formattimestamp(timestamp: number) {
    return isNaN(Number(timestamp)) || timestamp === -1
      ? ""
      : dateformat("P", timestamp);
  }

  private randomid() {
    return random({ length: 10, type: "base64" });
  }
}
