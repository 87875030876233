


















import { Component, Vue } from "vue-property-decorator";
import Navigation from "@views/components/Navigation.vue";
import Users from "@views/pages/admin/users/Main.vue";
import Claims from "@views/pages/admin/claims/Main.vue";
import ClaimRequests from "@views/pages/admin/claim-requests/Main.vue";
import Footer from "@views/components/Footer.vue";
import Emitter from "../../../services/events/event-bus";
import { Role, RoleType } from "../../../datatypes/Role";
import { USER_ROLE_ID } from "../../../services/config/constants";

@Component({
  components: {
    Navigation,
    Users,
    Claims,
    ClaimRequests,
    Footer
  }
})
export default class AdminPage extends Vue {
  private ontabchange(index: number) {
    Emitter.$emit("admin-console-tab-change", index);
  }

  get showEscalationTab() {
    return false;
    // const { Roles: roles = [] } = this.$store.getters["session/user"] || {};
    // return roles.some(
    //   (r: Role) =>
    //     r.ID === USER_ROLE_ID.CLAIM_REQUEST_REVIEWER ||
    //     r.ID === USER_ROLE_ID.RAINN_ADMIN
    // );
  }
}
