import { USER_ROLE_ID } from "./../services/config/constants";
export enum RoleType {
  PRIMITIVE = "primitive",
  GRANT = "grant"
}
export type RoleName = keyof typeof USER_ROLE_ID;
export type RoleID = typeof USER_ROLE_ID[RoleName];
export interface Role {
  Type: RoleType;
  ID: number;
  Name: string;
  Description: string;
  Active: boolean;
}
