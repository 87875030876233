



































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PendingTable from "@views/pages/admin/users/Tables/Pending.vue";
import ApprovedTable from "@views/pages/admin/users/Tables/Approved.vue";
import DeactivatedTable from "@views/pages/admin/users/Tables/Deactivated.vue";
import UserCreationForm from "@views/components/UserCreationForm.vue";
import UserUpdateForm from "@views/components/UserUpdateForm.vue";
import Toolbar from "@views/pages/admin/users/Toolbar.vue";
import Emitter from "../../../../services/events/event-bus";
import { User, UserStatus } from "../../../../datatypes/User";
import { Role } from "../../../../datatypes/Role";
import { getusers, getroles } from "../../../../services/actions";
import { scrollBehavior } from "../../../../util/dom";
const FlexSearch = require("flexsearch");

enum Tab {
  PENDING = 1,
  APPROVED = 2,
  DEACTIVATED = 3
}

interface SearchProgram {
  add: (users: User[]) => void;
  remove: (users?: User[]) => void;
  search: (
    input: string,
    {
      field,
      suggest,
      callback
    }: {
      field: string[];
      suggest: boolean;
      callback: (results: User[]) => void;
    }
  ) => void;
  destroy: () => void;
}

@Component({
  components: {
    ApprovedTable,
    PendingTable,
    DeactivatedTable,
    Toolbar,
    UserCreationForm,
    UserUpdateForm
  }
})
export default class Users extends Vue {
  users: User[] = [];
  statuses: UserStatus[] = [
    UserStatus.PENDING,
    UserStatus.ACTIVE,
    UserStatus.DEACTIVATED,
    UserStatus.INACTIVE
  ];

  searchprogram!: SearchProgram;
  selecteduser: User | null = null;
  errors: string[] = [];
  newusermodal: boolean = false;
  usermodal: boolean = false;
  searchtext: string = "";
  filteredusers: User[] = [];
  roles: Role[] = [];
  tab: Tab = Tab.APPROVED;
  loading: boolean = false;

  mounted() {
    this.subscriptions();
    this.initalize();
  }

  initalize() {
    getusers(
      (users: User[]) => {
        this.users = users;
      },
      (errors: Error[]) => {
        this.errors = errors.map(e => e.message);
        scrollBehavior("#admin-users-errors");
      }
    );

    getroles()
      .then(roles => (this.roles = roles))
      .catch((error: string) => {
        this.errors = [error];
        scrollBehavior("#admin-users-errors");
      });

    this.searchprogram = FlexSearch.create({
      doc: {
        id: "ID",
        field: ["FirstName", "LastName", "Email"]
      }
    });
  }

  subscriptions() {
    Emitter.$on("set-active-tab", this.setactivetab);
    Emitter.$on("search-user", this.setsearchtext);
    Emitter.$on("create-user", this.opencreatemodal);
    Emitter.$on("update-user", this.openupdatemodal);
  }

  @Watch("tab")
  ontabupdate(tab: Tab) {
    if ([Tab.PENDING, Tab.APPROVED, Tab.DEACTIVATED].includes(tab)) {
      this.searchtext = "";
      Emitter.$emit("clear-user-search-text");
    }
  }

  @Watch("totalcount")
  oncallbacksupdate(newVal: number, oldVal: number) {
    if (!isNaN(newVal) && newVal !== oldVal && newVal > 0) {
      this.searchprogram && this.searchprogram.remove(this.users);
      this.searchprogram && this.searchprogram.add(this.users);
      this.filter();
    } else {
      this.searchprogram && this.searchprogram.remove();
      this.filter();
    }
  }

  @Watch("filterparams", { deep: true })
  onfiltersupdate() {
    this.filter();
  }

  get totalcount() {
    return Array.isArray(this.users) ? this.users.length : 0;
  }

  get filterparams() {
    return { tab: this.tab, search: this.searchtext.trim() };
  }

  setactivetab(input: Tab) {
    this.tab = input;
  }

  setsearchtext(input: string) {
    this.searchtext = input.trim();
  }

  private filter() {
    this.loading = true;
    const applytabfilter = (user: User) => {
      return Number(user.Status) === Number(this.filterparams.tab);
    };

    if (this.filterparams.search.trim()) {
      this.searchprogram.search(this.filterparams.search.trim(), {
        field: ["FirstName", "LastName", "Email"],
        suggest: true,
        callback: (results: User[]) => {
          this.filteredusers = results.filter(applytabfilter);
          this.loading = false;
        }
      });
    } else {
      this.filteredusers = this.users.filter(applytabfilter);
      this.loading = false;
    }
  }

  openupdatemodal(user: User) {
    this.newusermodal = false;
    this.selecteduser = user;
    this.usermodal = true;
  }

  opencreatemodal() {
    this.usermodal = false;
    this.selecteduser = null;
    this.newusermodal = true;
  }

  closeupdatemodal() {
    this.usermodal = false;
    this.selecteduser = null;
  }

  closecreatemodal() {
    this.newusermodal = false;
    this.selecteduser = null;
  }

  cleanup() {
    this.errors = [];
    this.newusermodal = false;
    this.usermodal = false;
    this.selecteduser = null;
    this.tab = Tab.APPROVED;
    this.searchtext = "";
    this.filteredusers = [] as User[];
    this.searchprogram && this.searchprogram.destroy();
    this.loading = false;
  }
}
