




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PendingTable from "@views/pages/admin/claim-requests/Tables/Pending.vue";
import ReviewedTable from "@views/pages/admin/claim-requests/Tables/Reviewed.vue";
import CompletedTable from "@views/pages/admin/claim-requests/Tables/Completed.vue";
import ClaimRequestProcessingForm from "@views/components/ClaimRequestProcessingForm.vue";
import ClaimRequestCreationForm from "@views/components/ClaimRequestCreationForm.vue";
import Toolbar from "@views/pages/admin/claim-requests/Toolbar.vue";
import Emitter from "../../../../services/events/event-bus";

import {
  Claim,
  ClaimRequest,
  ClaimRequestStatus,
  ClaimRequestType
} from "../../../../datatypes/Claim";
import { getclaimrequests } from "../../../../services/actions";
import { scrollBehavior } from "../../../../util/dom";
const FlexSearch = require("flexsearch");

enum Tab {
  PENDING = 1,
  REVIEWED = 2,
  COMPLETED = 3
}

interface SearchProgram {
  add: (claimrequests: ClaimRequest[]) => void;
  remove: (claimrequests?: ClaimRequest[]) => void;
  search: (
    input: string,
    {
      field,
      suggest,
      callback
    }: {
      field: string[];
      suggest: boolean;
      callback: (results: ClaimRequest[]) => void;
    }
  ) => void;
  destroy: () => void;
}

@Component({
  components: {
    ReviewedTable,
    PendingTable,
    CompletedTable,
    ClaimRequestProcessingForm,
    ClaimRequestCreationForm,
    Toolbar
  }
})
export default class ClaimRequests extends Vue {
  claimmodal: boolean = false;
  newclaimmodal: boolean = false;
  selected: ClaimRequest | null = null;
  claimrequests: ClaimRequest[] = [];
  statuses: ClaimRequestStatus[] = [
    ClaimRequestStatus.PENDING,
    ClaimRequestStatus.APPROVED,
    ClaimRequestStatus.REJECTED,
    ClaimRequestStatus.COMPLETED
  ];

  searchprogram!: SearchProgram;
  errors: string[] = [];
  searchtext: string = "";
  filteredclaimrequests: ClaimRequest[] = [];
  tab: Tab = Tab.PENDING;
  loading: boolean = false;

  mounted() {
    this.subscriptions();
    this.initialize();
  }

  initialize() {
    getclaimrequests()
      .then(
        (claimrequests: ClaimRequest[]) => (this.claimrequests = claimrequests)
      )
      .catch(err => {
        console.error("failure to fetch claim requests", err);
        this.claimrequests = [];
      });

    this.searchprogram = FlexSearch.create({
      doc: {
        id: "ID",
        field: ["SID"]
      }
    });
  }

  subscriptions() {
    Emitter.$on("set-claim-requests-active-tab", this.setactivetab);
    Emitter.$on("search-claim-request", this.setsearchtext);
    Emitter.$on("claim-request-info", (data: ClaimRequest) => {
      this.selected = data;
      this.claimmodal = true;
      this.newclaimmodal = false;
    });

    Emitter.$on("create-claim-request", () => {
      this.selected = null;
      this.claimmodal = false;
      this.newclaimmodal = true;
    });
  }

  @Watch("tab")
  ontabupdate(tab: Tab) {
    if ([Tab.PENDING, Tab.REVIEWED, Tab.COMPLETED].includes(tab)) {
      this.searchtext = "";
      Emitter.$emit("clear-claim-requests-search-text");
    }
  }

  @Watch("totalcount")
  oncallbacksupdate(newVal: number, oldVal: number) {
    if (!isNaN(newVal) && newVal !== oldVal && newVal > 0) {
      this.searchprogram && this.searchprogram.remove(this.claimrequests);
      this.searchprogram && this.searchprogram.add(this.claimrequests);
      this.filter();
    } else {
      this.searchprogram && this.searchprogram.remove();
      this.filter();
    }
  }

  @Watch("filterparams", { deep: true })
  onfiltersupdate() {
    this.filter();
  }

  get totalcount() {
    return Array.isArray(this.claimrequests) ? this.claimrequests.length : 0;
  }

  get filterparams() {
    return { tab: this.tab, search: this.searchtext.trim() };
  }

  setactivetab(input: Tab) {
    this.tab = input;
  }

  setsearchtext(input: string) {
    this.searchtext = input.trim();
  }

  private filter() {
    this.loading = true;
    const applytabfilter = (claimrequest: ClaimRequest) => {
      switch (this.filterparams.tab) {
        case Tab.REVIEWED: {
          return [
            ClaimRequestStatus.APPROVED,
            ClaimRequestStatus.REJECTED
          ].includes(Number(claimrequest.Status));
        }
        case Tab.COMPLETED: {
          return [ClaimRequestStatus.COMPLETED].includes(
            Number(claimrequest.Status)
          );
        }

        default: {
          return Number(claimrequest.Status) === Number(this.filterparams.tab);
        }
      }
    };

    if (this.filterparams.search.trim()) {
      const targetfields = (() => {
        switch (this.filterparams.tab) {
          case Tab.PENDING: {
            return ["SID", "ClaimRequestCreationUser.Email"];
          }
          case Tab.REVIEWED: {
            return ["SID", "ClaimRequestReviewUser.Email"];
          }
          case Tab.COMPLETED: {
            return ["SID", "ClaimRequestCompletionUser.Email"];
          }
          default: {
            return ["SID"];
          }
        }
      })();

      this.searchprogram.search(this.filterparams.search.trim(), {
        field: targetfields,
        suggest: true,
        callback: (results: ClaimRequest[]) => {
          this.filteredclaimrequests = results.filter(applytabfilter);
          this.loading = false;
        }
      });
    } else {
      this.filteredclaimrequests = this.claimrequests.filter(applytabfilter);
      this.loading = false;
    }
  }

  cleanup() {
    this.errors = [];
    this.tab = Tab.PENDING;
    this.searchtext = "";
    this.selected = null;
    this.claimmodal = false;
    this.newclaimmodal = true;
    this.filteredclaimrequests = [] as ClaimRequest[];
    this.searchprogram && this.searchprogram.destroy();
    this.loading = false;
  }

  closemodal() {
    if (this.selected) {
      this.selected = null;
    }
    this.claimmodal = false;
    this.newclaimmodal = false;
  }
}
