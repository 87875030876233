










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CreatedTable from "@views/pages/admin/claims/Tables/Created.vue";
import SubmittedTable from "@views/pages/admin/claims/Tables/Submitted.vue";
import AcknowledgedTable from "@views/pages/admin/claims/Tables/Acknowledged.vue";
import Toolbar from "@views/pages/admin/claims/Toolbar.vue";
import Emitter from "../../../../services/events/event-bus";
import { Claim, ClaimStatus } from "../../../../datatypes/Claim";
import { getclaims } from "../../../../services/actions";
import { scrollBehavior } from "../../../../util/dom";
import { Role } from "../../../../datatypes/Role";
const FlexSearch = require("flexsearch");

enum Tab {
  CREATED = 1,
  SUBMITTED = 2,
  ACKNOWLEDGED = 3
}

interface SearchProgram {
  add: (claims: Claim[]) => void;
  remove: (claims?: Claim[]) => void;
  search: (
    input: string,
    {
      field,
      suggest,
      callback
    }: {
      field: string[];
      suggest: boolean;
      callback: (results: Claim[]) => void;
    }
  ) => void;
  destroy: () => void;
}

@Component({
  components: {
    SubmittedTable,
    CreatedTable,
    AcknowledgedTable,
    Toolbar
  }
})
export default class Claims extends Vue {
  claims: Claim[] = [];
  statuses: ClaimStatus[] = [
    ClaimStatus.CREATED,
    ClaimStatus.SUBMITTED,
    ClaimStatus.ACKNOWLEDGED
  ];

  searchprogram!: SearchProgram;
  errors: string[] = [];
  searchtext: string = "";
  filteredclaims: Claim[] = [];
  tab: Tab = Tab.CREATED;
  loading: boolean = false;

  mounted() {
    this.subscriptions();
    this.initialize();
  }

  get tabDisplayFlags() {
    return { created: true, submitted: this.isRainnAdmin, acknowledged: false };
  }

  get role(): Role | null {
    return this.$store.getters["session/role"];
  }

  get isRainnAdmin() {
    // RAINN ADMIN ROLE ID === 1
    return this.role ? this.role.ID === 1 : false;
  }

  initialize() {
    getclaims()
      .then((claims: Claim[]) => (this.claims = claims))
      .catch(err => {
        console.error("failure to fetch claims", err);
        this.claims = [];
      });
    this.searchprogram = FlexSearch.create({
      doc: {
        id: "ID",
        field: ["SID", "Category"]
      }
    });
  }

  subscriptions() {
    Emitter.$on("set-active-tab", this.setactivetab);
    Emitter.$on("search-claim", this.setsearchtext);
    Emitter.$on("admin-console-tab-change", (index: number) => {
      if (index === 1) {
        this.tab = Tab.CREATED;
      }
    });
  }

  @Watch("tab")
  ontabupdate(tab: Tab) {
    if ([Tab.CREATED, Tab.SUBMITTED, Tab.ACKNOWLEDGED].includes(tab)) {
      this.searchtext = "";
      Emitter.$emit("clear-claim-search-text");
    }
  }

  @Watch("totalcount")
  oncallbacksupdate(newVal: number, oldVal: number) {
    if (!isNaN(newVal) && newVal !== oldVal && newVal > 0) {
      this.searchprogram && this.searchprogram.remove(this.claims);
      this.searchprogram && this.searchprogram.add(this.claims);
      this.filter();
    } else {
      this.searchprogram && this.searchprogram.remove();
      this.filter();
    }
  }

  @Watch("filterparams", { deep: true })
  onfiltersupdate() {
    this.filter();
  }

  get totalcount() {
    return Array.isArray(this.claims) ? this.claims.length : 0;
  }

  get filterparams() {
    return { tab: this.tab, search: this.searchtext.trim() };
  }

  setactivetab(input: Tab) {
    this.tab = input;
  }

  setsearchtext(input: string) {
    this.searchtext = input.trim();
  }

  private filter() {
    this.loading = true;
    const applytabfilter = (claim: Claim) => {
      return Number(claim.Status) === Number(this.filterparams.tab);
    };

    if (this.filterparams.search.trim()) {
      this.searchprogram.search(this.filterparams.search.trim(), {
        field: ["SID", "Category"],
        suggest: true,
        callback: (results: Claim[]) => {
          this.filteredclaims = results.filter(applytabfilter);
          this.loading = false;
        }
      });
    } else {
      this.filteredclaims = this.claims.filter(applytabfilter);
      this.loading = false;
    }
  }

  cleanup() {
    this.errors = [];
    this.tab = Tab.CREATED;
    this.searchtext = "";
    this.filteredclaims = [] as Claim[];
    this.searchprogram && this.searchprogram.destroy();
    this.loading = false;
  }
}
