



























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ClaimRequest, ClaimRequestStatus } from "../../../../datatypes/Claim";
import Emitter from "../../../../services/events/event-bus";
import { Role } from "../../../../datatypes/Role";
import { USER_ROLE_ID } from "../../../../services/config/constants";

enum Tab {
  PENDING = 1,
  REVIEWED = 2,
  COMPLETED = 3
}

@Component
export default class ClaimRequestsToolbar extends Vue {
  @Prop(Array) claimrequests!: ClaimRequest[];
  tab: Tab = Tab.PENDING;
  searchtext: string = "";
  emitter: Vue = Emitter;

  mounted() {
    this.subscriptions();
  }

  subscriptions() {
    this.emitter.$on("clear-claim-requests-search-text", () => {
      this.searchtext = "";
    });

    this.emitter.$on("admin-console-tab-change", (index: number) => {
      if (index === 2) {
        this.tab = Tab.PENDING;
      }
    });
  }

  @Watch("tab", { immediate: true })
  ontabswitch() {
    this.emitter.$emit("set-claim-requests-active-tab", this.tab);
  }

  get role(): Role | null {
    return this.$store.getters["session/role"];
  }

  get isUberAdmin() {
    return this.role ? this.role.ID === USER_ROLE_ID.UBER_ADMIN : false;
  }

  get isRainnAdmin() {
    return this.role ? this.role.ID === USER_ROLE_ID.RAINN_ADMIN : false;
  }

  get pendingclaimrequests() {
    if (!Array.isArray(this.claimrequests)) {
      return [];
    }
    return this.claimrequests.filter(
      claim => claim.Status === ClaimRequestStatus.PENDING
    );
  }

  get reviewedclaimrequests() {
    if (!Array.isArray(this.claimrequests)) {
      return [];
    }
    return this.claimrequests.filter(claim =>
      [ClaimRequestStatus.APPROVED, ClaimRequestStatus.REJECTED].includes(
        claim.Status
      )
    );
  }

  get completedclaimrequests() {
    if (!Array.isArray(this.claimrequests)) {
      return [];
    }
    return this.claimrequests.filter(
      claim => claim.Status === ClaimRequestStatus.COMPLETED
    );
  }

  get activetabstyle() {
    return `
    text-decoration: underline; 
    text-decoration-color: rgb(248, 222, 42); 
    text-decoration-style: inherit;
    `;
  }

  create() {
    console.debug("claim requests...not implemented yet...");
    // window.location.replace("/create-referral");
  }

  download(version: string) {
    const param = ["uber:default", "rainn:default"].includes(version.trim())
      ? version.trim()
      : "uber:default";
    const link = `${window.location.origin}/api/v1/claim-requests?download=true&download_version=${param}`;

    window.open(link, "_blank");
  }
}
